import React, { useEffect } from 'react'

import { useParams, useHistory } from '../Router'
import { Spinner } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'

import { setUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'
import { checkIfCustomerIsExisting } from '../Settings/Store/Action'
import { useAppDispatch } from '../store/hooks'

export const AccountVerification: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { target, token, email, establishmentId } =
    useParams<{ [key: string]: string }>()

  useEffect(() => {
    setUserToken(establishmentId, token, email)
    dispatch(checkIfCustomerIsExisting(establishmentId, email))
  }, [])

  switch (target) {
    case 'planned-appointments':
      history.push(`/${establishmentId}/previous-appointments`)
      break
    case 'account':
      history.push(`/${establishmentId}/account`)
      break
    case 'account-info':
      history.push(`/${establishmentId}/account-info`)
      break
    case 'account-selection':
      history.push(`/${establishmentId}/account-selection/${token}`)
      break
    case 'home':
    default:
      history.push(`/${establishmentId}`)
      break
  }

  return (
    <StyledScrollView>
      <Spinner />
    </StyledScrollView>
  )
}
