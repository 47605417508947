import React, { CSSProperties } from 'react'

import { faUser } from '@fortawesome/pro-light-svg-icons'

import { Moment } from 'moment'
import { FaIcon } from '../../../../../App/Components'
import { CancelAppointmentButton } from '../../../CancelAppointmentButton/CancelAppointmentButton'
import { MoveAppointmentButton } from '../../../MoveAppointmentButton/MoveAppointmentButton'

import {
  AppointmentContainer,
  AppointmentData,
  AppointmentIconContainer,
  AppointmentText,
  AppointmentTextContainer,
  AppointmentWrapper,
} from './style'
import { useAppSelector } from '../../../../../store/hooks'
import { IPreviousAppointment } from '../../../../IPreviousAppointment'
import moment from 'moment'

type Props = {
  previousAppointment: IPreviousAppointment
  lockCancel: boolean
  lockMove: boolean
  momentDate: Moment
}

export const PreviousAppointment = ({
  previousAppointment,
  lockCancel,
  lockMove,
  momentDate,
}: Props) => {
  const settings = useAppSelector((state) => state.settings.values)

  const styledIconStyle: CSSProperties = {
    marginRight: '10px',
    height: '18px',
    width: '18px',
    alignSelf: 'baseline',
  }

  return (
    <AppointmentWrapper
      key={previousAppointment.id}
      highlightColor={settings.highlightColor}
    >
      <AppointmentContainer>
        <AppointmentData>
          <AppointmentIconContainer>
            <FaIcon
              cssStyle={styledIconStyle}
              icon={faUser}
              color={settings.fontColor}
            />
          </AppointmentIconContainer>
          <AppointmentTextContainer>
            <AppointmentText fontColor={settings.fontColor}>
              {moment(momentDate).format('HH:mm')}
            </AppointmentText>
            <AppointmentText fontColor={settings.fontColor}>
              {moment(momentDate).format('dddd D MMM YYYY')}
            </AppointmentText>
            {previousAppointment.activities.map((activity) => (
              <AppointmentText key={activity.id} fontColor={settings.fontColor}>
                {activity.name}
              </AppointmentText>
            ))}
          </AppointmentTextContainer>
        </AppointmentData>
        <MoveAppointmentButton
          cantMove={lockMove}
          backgroundcolor={settings.backgroundColor}
          highlight={settings.highlightColor}
          previousAppointment={previousAppointment}
        />

        <CancelAppointmentButton
          cantCancel={lockCancel}
          backgroundcolor={settings.backgroundColor}
          highlight={settings.highlightColor}
          previousAppointment={previousAppointment}
        />
      </AppointmentContainer>
    </AppointmentWrapper>
  )
}
