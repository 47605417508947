import React, { ComponentType } from 'react'
import { ValidateEmail } from '../ValidateEmail/ValidateEmail'
import { useHistory, useParams } from '../Router'
import { useAppSelector } from '../store/hooks'
import { Authenticated } from '../Authenticated/Authenticated'
import { Menu } from '../Menu'
import { ExtraActivity } from '../ExtraActivity'
import { checkIfCustomDuration } from '../App/Util/checkIfCustomDuration'
import { redirectTarget } from '../App/Util/redirectTarget'
import { TAuthTarget } from '../Authenticated/components/AuthenticationField/TAuthState'

type Props = {
  component: ComponentType
}

export const CustomerStop: React.FC<Props> = ({ component: Component }) => {
  const history = useHistory()
  const { target } = useParams<{ target: TAuthTarget | undefined }>()
  const settings = useAppSelector((state) => state.settings)
  const { isAccessGranted } = settings

  const isPaymentStatus = history.location.pathname.includes('payment-status')
  const isAnnulationCondition = history.location.pathname.includes(
    'annulation-condition'
  )

  if (
    checkIfCustomDuration(settings.values) &&
    settings.authentication.authState !== 'authenticated' &&
    !isPaymentStatus
  ) {
    return (
      <Authenticated
        oldTarget={target || redirectTarget(history.location.pathname)}
        Component={settings.values.isMenuVisible ? Menu : ExtraActivity}
      />
    )
  }

  if (isAccessGranted === false && !isAnnulationCondition && !isPaymentStatus) {
    return <ValidateEmail />
  }

  return <Component />
}
