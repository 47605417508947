import Client from './Client'
import { AppDispatch } from '../../store/store'
import {IInvoice} from "./IInvoice";

export const INVOICE_LOADING = 'INVOICE_LOADING'
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS'
export const INVOICE_FAILED = 'INVOICE_FAILED'

function invoiceLoading() {
  return {
    type: INVOICE_LOADING,
  }
}

function invoiceSuccess(invoice) {
  return {
    type: INVOICE_SUCCESS,
    invoice,
  }
}

function invoiceFailed() {
  return {
    type: INVOICE_FAILED,
  }
}

export function loadInvoice(
  establishmentId: string,
  token: string,
  invoiceUuid: string
) {
  return async (dispatch: AppDispatch) => {
    dispatch(invoiceLoading())
    try {
      const invoice = await Client.getInvoice(establishmentId, token, invoiceUuid)
      dispatch(invoiceSuccess(invoice))

      return invoice;
    } catch {
      dispatch(invoiceFailed())
    }
  }
}

export function pollInvoicePaymentStatus(establishmentId: string, token: string, invoiceUuid: string) {
  return async (dispatch: AppDispatch) => {
    const invoice = await dispatch(loadInvoice(establishmentId, token, invoiceUuid))
    if (invoice && !invoice.isPaid) {
      setTimeout(() => {
        dispatch(pollInvoicePaymentStatus(establishmentId, token, invoiceUuid))
      }, 2000)
    }
  }
}

