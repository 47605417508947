import {Action, Reducer} from 'redux'

import {
  INVOICE_LOADING,
  INVOICE_SUCCESS,
  INVOICE_FAILED
} from './Actions'
import { IInvoice } from './IInvoice'
import {IInvoiceState} from "./IInvoiceState";

const initialState: IInvoiceState = {
  loading: false,
  error: false
}
type TInvoiceAction = Action<typeof INVOICE_LOADING|typeof INVOICE_SUCCESS|typeof INVOICE_FAILED>&{invoice?: IInvoice}

export const reducer: Reducer<IInvoiceState, TInvoiceAction> = (
  // eslint-disable-next-line default-param-last
  state: IInvoiceState = initialState,
  action
): IInvoiceState => {
  switch (action.type) {
    case INVOICE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        invoice: action.invoice
      }
    case INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state
  }
}
