import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'

import { reducer as SettingsReducer } from '../Settings/Store/Reducer'
import { reducer as MenuStateReducer } from '../Menu/Store/Reducer'
import { reducer as AppointmentReducer } from '../Appointment/store/Reducer'
import { reducer as PersonsReducer } from '../SelectPerson/store/Reducer'
import { reducer as ActivityReducer } from '../ExtraActivity/Store/Reducer'
import { reducer as PaymentReducer } from '../PaymentFallback/store/Reducer'
import { reducer as LastMinuteReducer } from '../LastMinutes/store/Reducer'
import { reducer as SurveyReducer } from '../Survey/Store/Reducer'
import { reducer as SnackBar } from '../SnackBar/store/Reducer'
import { reducer as OptIns } from '../OptIn/Store/Reducer'
import { reducer as Account } from '../Account/store/Reducer'
import { reducer as WorkshopReducer } from '../Workshop/store/Reducer'
import { reducer as ContextMenuReducer } from '../App/Components/ContextMenu/store/reducer'
import { reducer as InvoiceReducer } from "../Invoice/store/Reducer";

// Create the root reducer separately, so we can extract the RootState type
const rootReducer = combineReducers({
  settings: SettingsReducer,
  menu: MenuStateReducer,
  appointment: AppointmentReducer,
  persons: PersonsReducer,
  activities: ActivityReducer,
  payment: PaymentReducer,
  lastMinute: LastMinuteReducer,
  survey: SurveyReducer,
  snackBar: SnackBar,
  optIns: OptIns,
  account: Account,
  workshops: WorkshopReducer,
  contextMenu: ContextMenuReducer,
  invoice: InvoiceReducer
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
