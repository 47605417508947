import { Action } from 'redux'

import Client from '../Client/Client'
import { ISettingValues } from '../ISettings'

import { ICustomerCheckResponse } from './ISettingsState'
import { TAuthState } from '../../Authenticated/components/AuthenticationField/TAuthState'
import { AppDispatch } from '../../store/store'

export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const SETTINGS_LOADING = 'SETTINGS_LOADING'
export const SET_CUSTOMER_EXISTS = 'SET_CUSTOMER_EXISTS'
export const REMOVE_CUSTOMER_EXISTS = 'REMOVE_CUSTOMER_EXISTS'
export const SET_CUSTOMER_DOES_NOT_EXIST = 'SET_CUSTOMER_DOES_NOT_EXIST'
export const SETTINGS_LOADING_FAILURE = 'SETTINGS_LOADING_FAILURE'
export const ENABLE_WAITING_QUEUE = 'ENABLE_WAITING_QUEUE'
export const GRAND_USER_ACCESS = 'GRAND_USER_ACCESS'
export const SET_CUSTOMER_SPECIFIC_DEPOSIT = 'SET_CUSTOMER_SPECIFIC_DEPOSIT'
export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const REMOVE_USER_ACCESS = 'REMOVE_USER_ACCESS'



type ISettingsLoading = Action<typeof SETTINGS_LOADING>

function settingsLoading(): ISettingsLoading {
  return {
    type: SETTINGS_LOADING,
  }
}
function settingsLoadingFailure() {
  return {
    type: SETTINGS_LOADING_FAILURE,
  }
}

function enableWaitingQueue(): Action<typeof ENABLE_WAITING_QUEUE> {
  return {
    type: ENABLE_WAITING_QUEUE,
  }
}

interface IGetSettingsSuccess
  extends Action<typeof GET_SETTINGS_SUCCESS> {
  values: ISettingValues
}

function getSettingsSuccess(values: ISettingValues): IGetSettingsSuccess {
  return {
    type: GET_SETTINGS_SUCCESS,
    values,
  }
}

function setCustomerExists(email: string) {
  return {
    type: SET_CUSTOMER_EXISTS,
    payload: { email: email },
  }
}

export function removeCustomerExists() {
  return {
    type: REMOVE_CUSTOMER_EXISTS,
  }
}

function setCustomerDoesNotExist() {
  return {
    type: SET_CUSTOMER_DOES_NOT_EXIST,
  }
}

export function validateEmailReset() {
  return {
    type: 'reset',
  }
}

export function grandUserAccess() {
  return {
    type: GRAND_USER_ACCESS,
  }
}

export function removeUserAccess() {
  return {
    type: REMOVE_USER_ACCESS,
  }
}

function setCustomerSpecificDeposit() {
  return {
    type: SET_CUSTOMER_SPECIFIC_DEPOSIT,
  }
}

export function setAuthState(
  authState: TAuthState,
  userToken?: string,
  userEmail?: string,
  errorMessage?: string
) {
  return {
    type: SET_AUTH_STATE,
    values: {
      authState: authState,
      userToken: userToken,
      userEmail: userEmail,
      errorMessage: errorMessage,
    },
  }
}

export function getSettings(establishmentId: string) {
  return (dispatch: AppDispatch) => {
    if (typeof establishmentId === 'undefined') {
      return
    }

    dispatch(settingsLoading())

    Client.getSettings(establishmentId).then(
      (values: ISettingValues) => {
        dispatch(getSettingsSuccess(values))
      },
      (error) => {
        if (error.response && error.response.status === 429) {
          dispatch(enableWaitingQueue())
          setTimeout(() => dispatch(getSettings(establishmentId)), 6000)
        } else {
          dispatch(settingsLoadingFailure())
        }
      }
    )
  }
}

export function checkIfCustomerIsExisting(
  establishmentId: string,
  email: string
) {
  return (dispatch: AppDispatch) =>
    Client.validateEmail(establishmentId, email)
      .then((response) => {
        const responseData: ICustomerCheckResponse = response.data

        // todo: Remove boolean response, is legacy
        if (typeof responseData === 'boolean') {
          if (responseData) {
            dispatch(setCustomerExists(email))
          } else {
            dispatch(setCustomerDoesNotExist())
          }
          return response
        }

        if (
          responseData.is_existing === true &&
          responseData.is_deposit_required
        ) {
          dispatch(setCustomerSpecificDeposit())
          dispatch(setCustomerExists(email))
        } else if (responseData.is_existing === true) {
          dispatch(setCustomerExists(email))
        } else if (responseData.is_deposit_required) {
          dispatch(setCustomerSpecificDeposit())
          dispatch(setCustomerExists(email))
        } else {
          dispatch(setCustomerDoesNotExist())
        }

        return response
      })
      .catch(() => {
        dispatch(setCustomerDoesNotExist())
      })
}
