import React, {useEffect} from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'

import {
  BackButton,
  ButtonWithText,
  Spinner,
  StatusMessageContainer,
  StatusText,
} from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { useParams } from '../../Router'
import { IAppState } from '../../store/IAppState'
import {pollInvoicePaymentStatus} from '../store/Actions'
import {useAppDispatch, useAppSelector} from "../../store/hooks";

export const PaymentStatus = () => {
  const { establishmentId, invoiceId, token } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { settings, invoice, isLoading, isError } =
    useAppSelector((state: IAppState) => {
      return {
        settings: state.settings.values,
        invoice: state.invoice.invoice,
        isLoading: state.invoice.loading,
        isError: state.invoice.error
      };
    })

  const downloadInvoice = () => {
    Linking.openURL(invoice.downloadUrl)
  }

  useEffect(() => {
    dispatch(pollInvoicePaymentStatus(establishmentId, token, invoiceId))
  }, [])

  ReactPixel.pageView()

  if (isLoading === true) {
    return <Spinner />
  }

  if (isError) {
    return (
      <StyledScrollView>
        <BackButton customPath={`/${establishmentId}`} />
        <StatusMessageContainer success={false}>
          <StatusText>{t('This URL has no payment confirmation.')}</StatusText>
        </StatusMessageContainer>
      </StyledScrollView>
    )
  }

  if (invoice && invoice.isPaid) {
    return (
      <StyledScrollView>
        <BackButton customPath={`/${establishmentId}/menu`} />
        <StatusMessageContainer success>
          <StatusText>{t('Invoice has been paid.')}</StatusText>
        </StatusMessageContainer>
        <ButtonWithText
          onPress={downloadInvoice}
          backgroundcolor={settings.backgroundColor}
          fontcolor={settings.highlightColor}
          hoversensitive
        >
          {t('Download invoice')}
        </ButtonWithText>
      </StyledScrollView>
    )
  }

  return <Spinner />
}
