import { URL_API } from 'react-native-dotenv'
import axios, {AxiosResponse} from "axios";

import {IInvoice, IApiInvoice} from "./IInvoice";

export default class Client {
  public static async getInvoice(
    establishmentId: string,
    token: string,
    invoiceUuid: string
  ): Promise<IInvoice> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/register/invoices/${invoiceUuid}/${token}?establishment_id=${establishmentId}`,
    }

    const response: AxiosResponse<IApiInvoice> = await axios(requestOptions)
    if (response.status >= 400) {
      throw Error('Request failed')
    }

    return {
      ...response.data,
      isPaid: response.data.is_paid,
      downloadUrl: response.data.download_url
    }
  }
}
