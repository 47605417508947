import styled from 'styled-components/native'

export const AppointmentWrapper = styled.View<{
    highlightColor: string
}>`
    margin-top: 30px;
    background-color: ${({ highlightColor }) => highlightColor + 15};
`

export const AppointmentContainer = styled.View`
    margin: 30px 70px;
    min-width: 200px;
`

export const AppointmentIconContainer = styled.View`
    justify-content: center;
    margin-right: 10px;
`

export const AppointmentData = styled.View`
    padding-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 95%;
`

export const AppointmentText = styled.Text<{
    fontColor: string
}>`
    color: ${({ fontColor }) => fontColor};
    margin: 2px 0;
`

export const AppointmentTextContainer = styled.Text`
    max-width: 100%;
    display: flex;
    flex-direction: column;
`
